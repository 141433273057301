import * as React from 'react';
import {ReactNode, useContext} from 'react';
import {PageProps} from "gatsby";

const PageStateContext = React.createContext(null);

export type PageState = Omit<PageProps, 'children'>;
export type PageStateProps = PageState & {
    children: ReactNode;
};
export function PageState({children, ...pageProps}: PageStateProps) {
    return <PageStateContext.Provider value={pageProps}>
        {children}
    </PageStateContext.Provider>
}


export function usePageState() {
    return useContext(PageStateContext);
}
