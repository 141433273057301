import * as React from 'react';
import styled from 'styled-components';
import {AppBarHeight, CenterSectionCSS, Media} from "../constants";
import {Link} from "./Link";

export type AppBarProps = {
    className?: string;
}

export function AppBar({className}: AppBarProps) {
    return <Container className={className}>
        <div className={'bkg'}/>
        <Content>
            <Title><Link to={"/#home"}>WanaBuddy Block Pages</Link></Title>
        </Content>
    </Container>
}


const Container = styled.header`
  position: fixed;
  flex: 0 0 auto;
  height: ${AppBarHeight}rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;  
`;
const Content = styled.div`
  ${CenterSectionCSS};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding: 1rem;
  
  @media (${Media.desktop}) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  
  @media (${Media.desktop}) {
    font-size: 2rem;
  }
`;
