import "normalize.css"
import {createGlobalStyle} from 'styled-components';
import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import {Media, Palette} from "../constants";
import {RecoilRoot} from "recoil";
import * as React from "react";
import {ReactNode} from "react";
import styled from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'nunito', sans-serif;
  }
  html, body {
      margin: 0;
      padding: 0;      
  }
  
  .p-button.p-button-secondary.p-button-text {
    color: #${Palette.BlueYonder.hex};
  }
`;

export type RootProps = {
    children: ReactNode;
    className?: string;
}
export function Root({children, className}: RootProps) {
    return <RecoilRoot>
        <GlobalStyle/>
        <Container className={className}>
            {children}
        </Container>
    </RecoilRoot>;
}


const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  background-color: #${Palette.MaximumYellowRed.hex};      
  
  @media (${Media.laptop}) {
    min-height: 100vh;  
  }
`;

